@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root, .app-wrapper, .layout-wrapper{
  height: 100vh;
}

/* utils */
.max-w-768{
  max-width: 768px;
}

/* App */
.app{
  background: #F1F1F1;
  text-transform: lowercase;
  font-family: 'Anonymous Pro', sans-serif;
}
/* navigation */
.navbar-container{
  max-width: 1100px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.navbar-container ul{
  max-width: 768px;
  margin-right: auto;
  margin-left: auto;
}

/* backgrounds */
.bg-teal{
  background: rgb(19, 79, 92);
}
.bg-nice-pink{
  background: #ffd6bf
}

/* footer-logo */
.footer-logo img{
  height: 150px;
  width: 150px;
  max-width: 100%;
}
.footer-text{
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}